import { DashboardLayout } from "components/layouts/DashboardLayout";
import { Slide } from "react-awesome-reveal";
import "./styles.scss";
import { useCallback } from "react";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as ChartUp } from "assets/imgs/chartup.svg";
import { ReactComponent as Stars } from "assets/imgs/stars.svg";
import { CButton } from "components/atoms/CButton";
import { CDropdown } from "components/atoms/CDropdown";
import { CInput } from "components/atoms/CInput";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import { PhotoCard } from "components/atoms/PhotoCard";
import useContent from "hooks/studio/useContent";
import { LICENSE } from "utils/constants";
import { getLicense, isPossible, showLicense } from "utils/util";
import { Grid } from "@mui/material";

export function MyFeed() {
  const { onImportImage } = useContent();
  const {
    showNoti,
    setLoading,
    showImageModal,
    checkToken,
    scaleImage,
    clarityImage,
    user,
  } = useContext(LayoutContext);
  const [activeTrend, setActiveTrend] = useState("trending"); // trending || new || top
  const [activeMode, setActiveMode] = useState("all"); // all | upscaled
  const [viewOptions, setViewOptions] = useState([
    {
      key: 1,
      id: "image-to-image",
      icon: null,
      color: "white",
      checked: false,
      label: "Product Photoshoot",
    },
    {
      key: 2,
      id: "all",
      icon: null,
      color: "white",
      checked: true,
      label: "All Images",
    },
    {
      key: 3,
      id: "image-animation",
      icon: null,
      color: "white",
      checked: false,
      label: "Image Animations",
    },
    {
      key: 4,
      id: "upscale",
      icon: null,
      color: "white",
      checked: false,
      label: "Image Upscale",
    },
  ]);

  const [activeViewOpt, setActiveViewOpt] = useState("all"); // all | upscaled
  const [keyword, setKeyword] = useState("");
  const [trendImgs, setTrendImgs] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const onSearch = () => {
    getViewImages(activeTrend, activeViewOpt, keyword, 1);
  };

  const handleLike = async (image_id, liked) => {
    try {
      checkToken();
      await getApi("/like", "POST", {
        image_id,
        is_like: !liked ? "yes" : "no",
      });
      const tmp = [...trendImgs];
      tmp.forEach((obj) => {
        if (obj.id === image_id) {
          obj.liked = !liked;
          if (!liked) obj.like_ct = Number(obj.like_ct) + 1;
          else obj.like_ct = Number(obj.like_ct) - 1;
        }
      });

      setTrendImgs(tmp);
    } catch (err) {
      showNoti("error", err);
    }
  };

  const handleDelete = async (image_id) => {
    try {
      setLoading(true);
      checkToken();
      await getApi("/deleteImage", "POST", { image_id });
      showNoti("success", "Image Deleted Successfully!");
      getViewImages(activeTrend, activeViewOpt, keyword);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const handleDownload = (imageUrl, fileName) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        showNoti("error", "Error downloading image");
      });
  };

  const onClickImageMode = (tp) => {
    if (tp !== activeTrend) {
      getViewImages(tp, activeViewOpt, keyword, 1);
    }
    setActiveTrend(tp);
  };

  const onReadMore = () => {
    let curPage = currentPage;
    curPage++;
    getViewImages(activeTrend, activeViewOpt, keyword, curPage);
  };

  const getViewImages = async (
    trend = "trending",
    viewOption = "all",
    searchString = keyword,
    curPage = 1
  ) => {
    try {
      setLoading(true);
      setIsLoading(true);
      checkToken();
      const data = await getApi("/getImages", "POST", {
        page_size: 20,
        feed_type: "personal",
        view_category: trend,
        keyword: searchString,
        user_id: user.user.id,
        generate_type: viewOption,
        page: curPage,
      });
      setCurrentPage(curPage);
      if (curPage > 1) {
        setTrendImgs([...trendImgs, ...data.images]);
      } else {
        setTrendImgs(data.images);
      }
      setIsLast(data.last);
    } catch (err) {
      showNoti("error", err);
    }
    setIsFetch(false);
    setLoading(false);
    setIsLoading(false);
  };

  const handleOptRatio = (id, index, value) => {
    switch (id) {
      case "view_option":
        const customOptions = [...viewOptions];
        customOptions.forEach((el) => (el.checked = false));
        customOptions[index].checked = value;
        getViewImages(activeTrend, customOptions[index].id, keyword, 1);
        setActiveViewOpt(customOptions[index].id);
        setViewOptions(customOptions);
        break;
      default:
    }
  };

  const getActiveMenu = (arr) => {
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };

  useEffect(() => {
    getViewImages();
  }, []);

  useEffect(() => {
    const myFeedElement = document.getElementById("my_feed");
    if (myFeedElement) {
      myFeedElement.addEventListener("scroll", handleScroll, true);
      return () =>
        myFeedElement.removeEventListener("scroll", handleScroll, true);
    }
  }, [isLoading]);

  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      const scrollPercentage =
        (scrollTop / (scrollHeight - offsetHeight)) * 100;
      if (scrollPercentage > 95) {
        setIsFetch(true);
      }
    },
    [isLast, onReadMore]
  );

  useEffect(() => {
    let curPage = currentPage;
    curPage++;
    if (curPage > 1) {
      if (isLast && isFetch) {
        onReadMore();
      }
    }
  }, [isFetch, isLast]);

  const checkLicense = (license) => {
    const isValid = isPossible(getLicense(user?.user), license);

    if (!isValid) {
      let missingLicense = "";
      for (const permissionSet of license) {
        for (const prmsn of permissionSet) {
          if (!user?.user?.license.includes(prmsn)) {
            missingLicense = [prmsn];
            if (
              missingLicense.includes(LICENSE.BUSINESS) ||
              missingLicense.includes(LICENSE.STARTER)
            ) {
              missingLicense = [LICENSE.BUSINESS, LICENSE.STARTER];
            }
            break;
          }
        }
        if (missingLicense) break;
      }

      const formatLicense = showLicense(missingLicense, true);

      showNoti(
        "info",
        `You need to upgrade to a ${formatLicense} license to unlock this feature.`
      );
    }

    return isValid;
  };

  const handleEdit = (image) => {
    // if (user?.user?.license && !user.user.license.includes(LICENSE.PROFESSIONAL)) {
    //   showNoti(
    //     "info",
    //     `You need to upgrade to a Pro license to unlock this feature.`
    //   ); return;
    // }
    if (
      checkLicense([
        [LICENSE.PROFESSIONAL, LICENSE.BUSINESS],
        [LICENSE.PROFESSIONAL, LICENSE.STARTER],
      ])
    ) {
      onImportImage(image);
    }
  };

  return (
    <DashboardLayout
      page="my-feed"
      caption="Personal Feed"
      message="Welcome to Creativio AI"
      license={[[LICENSE.BUSINESS], [LICENSE.STARTER]]}
    >
      <div id="my_feed" className="body-container" onScroll={handleScroll}>
        <div className="section2">
          <Slide>
            <h1>
              <span>Personal</span> Feed
            </h1>
          </Slide>
          <div className="btn-actions">
            <CButton
              addClass="gradient"
              active={activeTrend === "trending"}
              onClick={() => onClickImageMode("trending")}
            >
              Trending
            </CButton>
            <CButton
              addClass="gradient"
              active={activeTrend === "new"}
              onClick={() => onClickImageMode("new")}
            >
              <Stars className="btn-icon" />
              New
            </CButton>
            <CButton
              addClass="gradient"
              active={activeTrend === "top"}
              onClick={() => onClickImageMode("top")}
            >
              <ChartUp className="btn-icon" />
              Top
            </CButton>
            <CDropdown
              id="view_option"
              addClass="w-220"
              showIcon={false}
              action={handleOptRatio}
              activeMenu={getActiveMenu(viewOptions)}
              menu={viewOptions}
            />
          </div>
          <div className="btn-group mt-15">
            <div className="search-area">
              <CInput
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                addClass="full text-white"
              />
              <CButton addClass="gradient" active={true} onClick={onSearch}>
                Search
              </CButton>
            </div>
            <div className="btn-mode">
              <CButton
                addClass="gradient"
                active={activeTrend === "all"}
                // onClick={() => setActiveMode("all")}
                onClick={() => onClickImageMode("all")}
              >
                All
              </CButton>
              <CButton
                addClass="gradient"
                active={activeTrend === "upscale"}
                // onClick={() => setActiveMode("upscaled")}
                onClick={() => onClickImageMode("upscale")}
              >
                Upscaled
              </CButton>
              <CButton
                addClass="gradient"
                active={activeTrend === "hd-clarity"}
                onClick={() => onClickImageMode("hd-clarity")}
              >
                HD Clarity
              </CButton>
            </div>
          </div>
          <div className="trend-area mt-25">
            {trendImgs && trendImgs.length !== 0 ? (
              <Grid container spacing={3} columns={21}>
                {trendImgs.map((k, index) => (
                  <Grid
                    item
                    xs={21}
                    sm={21}
                    md={10.5}
                    lg={7}
                    xl={5.25}
                    xxl={4.2}
                    xl3={3}
                    xl4={2.625}
                  >
                    <PhotoCard
                      key={index}
                      image={k}
                      images={trendImgs}
                      onView={showImageModal}
                      onUpScale={scaleImage}
                      onClarity={clarityImage}
                      onDownload={handleDownload}
                      onLike={handleLike}
                      deleteImage={true}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div className="no-records-message">
                <p>No records found.</p>
              </div>
            )}
          </div>
          {isLast && trendImgs.length > 0 && (
            <div className="text-left mt-20 mb-20">
              <CButton addClass="gradient" active={true} onClick={onReadMore}>
                Load More
              </CButton>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
