import React from 'react';
import CImage from "components/atoms/CImage";
import { ReactComponent as IconRemove } from "assets/imgs/action/icon_delete.svg";

import "./styles.scss";

const LogoCard = ({ alt, imgUrl, onDelete, isFile, width = 225, height = 225 }) => {
    return (
        <div className='logo-card'>
            <CImage src={imgUrl} alt={alt} width={width} height={height} />
            <div className="card-action-bar">
                <div className="last-part">
                    <IconRemove onClick={() => onDelete(isFile)} className="action-btn"
                        data-tooltip-id="tip-logo-delete"
                        data-tooltip-variant="light"
                        data-tooltip-content="Delete" />
                </div>
            </div>
        </div>
    );
};

export default LogoCard;