import { DashboardLayout } from "components/layouts/DashboardLayout";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import { useContext, useEffect, useRef, useState } from "react";
import { Slide, Fade } from "react-awesome-reveal";
import { CButton } from "components/atoms/CButton";
import { ReactComponent as Plus } from "assets/imgs/plus_white.svg";
import { ReactComponent as IcoDelete } from "assets/imgs/action/icon_delete.svg";
import { ReactComponent as IcoEdit } from "assets/imgs/action/icon_edit.svg";
import { ReactComponent as IcoChecked } from "assets/imgs/auth/checked.svg";
import { ReactComponent as IcoUnChecked } from "assets/imgs/auth/unchecked.svg";
import { ReactComponent as ArrowBack } from "assets/imgs/arrow_back.svg";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
// import { BrandKitModal } from "components/atoms/Modal/BrandKitModal";
import { getApi } from "utils/customNetwork";
import { LICENSE } from "utils/constants";
import { PhotoshopPicker } from "react-color";
import { Tooltip } from "react-tooltip";
import LogoCard from "components/atoms/LogoCard";
import { CDropdown } from "components/atoms/CDropdown";
import { ReactComponent as Upload } from "assets/imgs/upload.svg";
import { ReactComponent as Edit } from "assets/imgs/edit.svg";
import { ReactComponent as PlusPure } from "assets/imgs/plus_pure.svg";
import { ReactComponent as Save } from "assets/imgs/save.svg";

import "./styles.scss";

export function NewBrand() {
  const [stage, setStage] = useState("view"); // view || new || update
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const { showNoti, setLoading, checkToken, user } = useContext(LayoutContext);
  const [brands, setBrands] = useState([]); // brands
  const [selBrand, setSelBrand] = useState(null);
  // const [showBrandModal, setShowBrandModal] = useState(false);
  const fileInputRef = useRef(null);
  const [brandName, setBrandName] = useState("");
  const [newImages, setNewImages] = useState([]); // new images - file
  const [newImgUrls, setNewImgUrls] = useState([]); // new images - url
  const [newColor, setNewColor] = useState(null); // new color
  const [newColors, setNewColors] = useState([]); // new colors
  const [isBrandEffect, setIsBrandEffect] = useState(false);
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [displayLogoInput, setDisplayLogoInput] = useState(false);
  const [displayColorInput, setDisplayColorInput] = useState(false);
  const [editIndex, setEditIndex] = useState(null); // Track the index being edited
  const [existingImgUrls, setExistingImgUrls] = useState([]); // Existing URL-based images from the edit functionality
  const [deletedUrls, setDeletedUrls] = useState([]); // Track deleted URLs
  const [positionOptions, setPositionOptions] = useState([
    { key: 1, id: "top-left", checked: true, label: "Top Left" },
    { key: 2, id: "top-right", checked: false, label: "Top Right" },
    { key: 3, id: "bottom-left", checked: false, label: "Bottom Left" },
    { key: 4, id: "bottom-right", checked: false, label: "Bottom Right" },
    { key: 5, id: "center", checked: false, label: "Center" },
    { key: 6, id: "no_watermark", checked: false, label: "No Watermark" },
  ]);

  const onAddLogoBtnClick = () => {
    setDisplayLogoInput(true);
  };

  const onImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      const tempImages = [...newImages];
      tempImages.push(file);
      setNewImages(tempImages);
      reader.onload = () => {
        const tempImgUrls = [...newImgUrls];
        tempImgUrls.push(reader.result);
        setNewImgUrls(tempImgUrls);
      };

      reader.readAsDataURL(file);
    }
    setDisplayLogoInput(false);
  };

  const onDeleteLogo = (index, isFile) => {
    if (isFile) {
      // Handle deletion of a newly added file-based logo
      const updatedLogos = [...newImgUrls];
      updatedLogos.splice(index, 1);
      setNewImgUrls(updatedLogos);

      // Remove the associated image file
      const updatedImages = [...newImages];
      updatedImages.splice(index, 1);
      setNewImages(updatedImages);
    } else {
      // Handle deletion of an existing URL-based logo (from edit functionality)
      const updatedLogos = [...existingImgUrls];
      const deletedUrl = updatedLogos.splice(index, 1)[0];
      setExistingImgUrls(updatedLogos);
      setDeletedUrls([...deletedUrls, deletedUrl]);
    }
  };

  const onAddColor = () => {
    setDisplayColorInput(true);
  };

  const onShowPalette = () => {
    setShowColorPalette(true);
  };

  const onClosePalette = () => {
    setShowColorPalette(false);
    setEditIndex(null); // Reset the edit index when closing the palette
  };

  const onSelectColor = (color) => {
    setNewColor(color.hex);
  };

  const onDeleteColor = (index) => {
    const updatedColors = [...newColors];
    updatedColors.splice(index, 1);
    setNewColors(updatedColors);
  };

  const onEditColor = (index, initialColor) => {
    setEditIndex(index);
    setNewColor(initialColor);
    onShowPalette(); // Show the color palette when editing
  };

  const onManageColor = () => {
    if (newColor !== null) {
      if (editIndex !== null) {
        // If editing, update the color at the specified index
        const updatedColors = [...newColors];
        updatedColors[editIndex] = newColor;
        setNewColors(updatedColors);
      } else {
        // If not editing, add the new color
        setNewColors([...newColors, newColor]);
      }
      onClosePalette(); // Close the palette after managing the color
    }
  };

  const getActiveMenu = (arr) => {
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };

  const handleOptRatio = (id, index, value) => {
    switch (id) {
      case "position":
        const c1 = [...positionOptions];
        c1.forEach((el) => (el.checked = false));
        c1[index].checked = value;
        setPositionOptions(c1);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (brandName == '') {
      showNoti("info", "Please input a brand name.");
      return;
    }
    formData.append("brand_name", brandName);
    formData.append("brand_colors", newColors.join());
    formData.append("position", getActiveMenu(positionOptions).id);
    if (newImages.length > 0) {
      newImages.forEach((value, index) => {
        formData.append('images[]', value);
      });
    }
    let api = "/saveBrand";
    if ((selBrand && selBrand.id)) {
      api = "/updateBrand";
      formData.append("brand_id", selBrand?.id);
      if (deletedUrls.length > 0) {
        deletedUrls.forEach((value, index) => {
          formData.append('deleted_logos[]', value);
        });
      }
      if ((newImages.length + existingImgUrls.length) == 0) {
        showNoti("info", "Please enter a logo.");
        return;
      }
    }
    else {
      if (newImages.length == 0) {
        showNoti("info", "Please enter a logo.");
        return;
      }
    }
    try {
      setLoading(true);
      checkToken();
      const data = await getApi(api, "POST", formData);
      if (selBrand?.id) {
        showNoti("success", "Brand Kit Updated Successfully!");
      }
      else {
        showNoti("success", "Brand Kit Created Successfully!");
      }
      setBrands(preworkBrand(data.data));
      // onHandleUpdate(data.data);
      onCloseBrandModal();
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selBrand) {
      if (selBrand.position && !isBrandEffect) {
        const findIndex = positionOptions.findIndex((el) => el.id === selBrand.position);
        handleOptRatio("position", findIndex, true);
        setIsBrandEffect(true);
      }
    }
  }, [selBrand, handleOptRatio, positionOptions]);

  const OnCreateBrand = () => {
    setStage('update');
    setSelBrand(null);
    // setShowBrandModal(true);
  };

  const onCloseBrandModal = () => {
    setStage('view');
    setSelBrand(null);
    setBrandName('');
    setNewColors([]);
    setExistingImgUrls([]);
    setDeletedUrls([]);
    setNewImages([]);
    setNewImgUrls([]);
    setNewColor(null);
    setIsBrandEffect(false);
    setShowColorPalette(false);
    setDisplayLogoInput(false);
    setDisplayColorInput(false);
    // setShowBrandModal(false);
  };

  const onBrandEdit = (brandItem) => {
    setStage('update');
    setSelBrand(brandItem);
    setBrandName(brandItem.brandName);
    setNewColors(brandItem.brandColors);
    setExistingImgUrls(brandItem.brandImages);
    // setShowBrandModal(true);
  };

  const confirmDeletion = async (brand) => {
    try {
      // Ensure that checkToken, getApi, showNoti, and getBrands are defined
      setLoading(true);
      checkToken();
      await getApi("/deleteBrand", "POST", { brand_id: brand.id });
      showNoti("success", "Brand Kit Deleted Successfully!");
      getBrands();
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const onBrandDelete = (brand) => {
    setItemToDelete(brand);
    setShowConfirmation(true);
  };

  const closeDeleteConfirmationModal = () => {
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const preworkBrand = (arr) => {
    const res = [];
    for (let i = 0; i < arr.length; i++) {
      const brandName = arr[i].brand_name;
      const position = arr[i].position;
      const isActive = Number(arr[i].is_active) === 1 ? true : false;
      const brandColors = arr[i].colors ? arr[i].colors.split(",") : [];
      const brandImages = arr[i].images ? arr[i].images.split(",") : [];
      res.push({
        id: arr[i].id,
        brandName,
        brandColors,
        brandImages,
        isActive,
        position,
      });
    }
    return res;
  };

  const onSetDefaultBrand = async (brandId) => {
    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/selectDefaultBrand", "POST", {
        brand_id: brandId,
      });
      setBrands(preworkBrand(data.data));
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const getBrands = async () => {
    try {
      checkToken();
      setLoading(true);
      const data = await getApi("/getBrands", "POST", {
        user_id : user.user.id
      });
      setBrands(preworkBrand(data.data));
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBrands();
  }, []);

  const renderBrands = () => {
    if (brands.length > 0) {
      return (
        <div className="brand-view">
          {brands.map((k, index) => (
            <div className="brand-card border-gradient border-10" key={index}>
              <div className="brand-body">
                <div className="brand-image-container">
                  {k.brandImages.map((img, idx) => {
                    if (idx === 0) {
                      return (
                        <div className="brand-image" key={idx}>
                          <img src={img} />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <div className="brand-color-container">
                  {k.brandColors.map((col, i) => (
                    <div
                      className="brand-color"
                      key={i}
                      style={{
                        backgroundImage: `linear-gradient(${col}, ${col}), linear-gradient(to right, #EB00FF, #760CFE)`,
                      }}
                    >
                      &nbsp;
                    </div>
                  ))}
                </div>
              </div>
              <div className="brand-title-container">
                <div className="brand-title">{k.brandName} Branding Kit</div>
                <div className="brand-actions">
                  {k.isActive ? (
                    <IcoChecked className="ico-26" />
                  ) : (
                    <IcoUnChecked
                      className="ico-26"
                      onClick={() => onSetDefaultBrand(k.id)}
                    />
                  )}
                  <IcoEdit onClick={() => onBrandEdit(k)} />
                  <IcoDelete onClick={() => onBrandDelete(k)} />
                  {showConfirmation && (
                    <ConfirmationModal
                      isOpen={showConfirmation}
                      onClose={closeDeleteConfirmationModal}
                      onConfirm={() => confirmDeletion(itemToDelete)}
                      itemModule="Brand Kit"
                      itemName={itemToDelete ? itemToDelete.brandName : ''}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <p>You don’t have any Brand Created. Start by Adding Your New Brand</p>
      );
    }
  };

  return (
    <DashboardLayout
      page="newbrand"
      caption="New Brand"
      license={[[LICENSE.PROFESSIONAL, LICENSE.BUSINESS], [LICENSE.PROFESSIONAL, LICENSE.STARTER]]}
      message="Customize and set your Branding"
    >
      <div id="brand" className="body-container">
        <div className="head">
          <Slide className="flex1">
            <h1>
              <span>Brand</span> Kit
            </h1>
          </Slide>
          {stage === "view" && (
            <CButton
              mainClass="mt-20"
              addClass="gradient p-5I"
              active={true}
              onClick={OnCreateBrand}
            >
              <Plus className="btn-icon" />
              Add New Brand
            </CButton>
          )}
          {stage === "update" && (
            <CButton
              mainClass="mt-20"
              addClass="gradient p-5I"
              active={true}
              onClick={onCloseBrandModal}
            >
              <ArrowBack className="btn-icon" />
              Back To Brand Kit
            </CButton>
          )}
        </div>
        {stage === "view" && renderBrands()}
        {stage === "update" && (
          <div className="brand-area">
            <h2>{selBrand?.id ? "Edit" : "Create New"} Brand Kit</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group mt-10">
                <div className="brand-name">
                  <p>Brand Name</p>
                  <div className="input-field mt-10">
                    <input
                      type="text"
                      id="brand_name"
                      placeholder="Brand Name"
                      value={brandName}
                      onChange={(e) => setBrandName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="logo-area mt-10">
                  <div className="toolbar">
                    <p>
                      Logos ({newImgUrls.length + existingImgUrls.length})
                    </p>
                    <CButton
                      mainClass="mt-5"
                      addClass="gradient p-5I"
                      active={true}
                      onClick={onAddLogoBtnClick}
                    >
                      <Plus className="btn-icon" />
                      Add New
                    </CButton>
                  </div>
                  {displayLogoInput && (
                    <div className="logo-uploading-area mt-10">
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/gif"
                        onChange={handleImageUpload}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <div
                        className="custom-upload border-gradient"
                        onClick={onImageUpload}
                      >
                        <Upload />
                        <p>Add a logo or just drag and drop one here</p>
                      </div>
                    </div>
                  )}
                </div>
                {(existingImgUrls.length > 0 || newImgUrls.length > 0) && (
                  <div className="uploaded-logos mt-10">
                    {/* Render existing URL-based images from edit functionality */}
                    {existingImgUrls.map((imgUrl, index) => (
                      <LogoCard
                        key={index}
                        alt={brandName}
                        imgUrl={imgUrl}
                        onDelete={(isFile) => onDeleteLogo(index, isFile)}
                        isFile={false}
                      />
                    ))}

                    {/* Render newly added file-based images */}
                    {newImgUrls.map((imgUrl, index) => (
                      <LogoCard
                        key={index}
                        alt={brandName}
                        imgUrl={imgUrl}
                        onDelete={(isFile) => onDeleteLogo(index, isFile)}
                        isFile={true}
                      />
                    ))}
                    <Tooltip id="tip-logo-delete" />
                  </div>
                )}
                <div className="color-area mt-10">
                  <div className="toolbar">
                    <p>
                      Colors ({newColors.length})
                    </p>
                    <CButton
                      mainClass="mt-5"
                      addClass="gradient p-5I"
                      active={true}
                      onClick={onAddColor}
                    >
                      <Plus className="btn-icon" />
                      Add New
                    </CButton>
                  </div>
                  <div className="color-selecting-area mt-10 border-gradient">
                    <div className="color-toolbar">
                      <p>
                        Color Palette
                      </p>
                    </div>
                    <div className="color-body">
                      {newColors.map((k, index) => (
                        <div key={index} className="color-group">
                          <div
                            className="color-item border-gradient"
                            style={{ background: `${k}` }}
                          >
                            <div className="card-action-bar">
                              <div className="last-part">
                                <Edit className="action-btn" onClick={() => onEditColor(index, k)}
                                  data-tooltip-id="tip-edit"
                                  data-tooltip-variant="light"
                                  data-tooltip-content="Edit" />
                                <Tooltip id="tip-edit" />
                                <IcoDelete className="action-btn" onClick={() => onDeleteColor(index)}
                                  data-tooltip-id="tip-delete"
                                  data-tooltip-variant="light"
                                  data-tooltip-content="Delete" />
                                <Tooltip id="tip-delete" />
                              </div>
                            </div>
                          </div>
                          <p>{k}</p>
                        </div>
                      ))}
                      {(newColors.length > 0 || displayColorInput) && (
                        <div className="color-new border-gradient">
                          <PlusPure className="cursor-pointer" onClick={onShowPalette} />
                        </div>
                      )}
                    </div>
                    {showColorPalette && (
                      <div className="color-palette-div">
                        <PhotoshopPicker
                          className="color-palette"
                          color={newColor ? newColor : "#000"}
                          onChange={onSelectColor}
                          onAccept={onManageColor}
                          onCancel={onClosePalette}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="position-area mt-10">
                  <div className="toolbar">
                    <p>
                      Logo Position
                    </p>
                  </div>
                  <div className="position-selecting-area mt-10 border-gradient">
                    <div className="position-toolbar">
                      <p>Logo Watermark Position</p>
                    </div>
                    <div className="position-body">
                      <CDropdown
                        id="position"
                        addClass="product"
                        action={handleOptRatio}
                        activeMenu={getActiveMenu(positionOptions)}
                        menu={positionOptions}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <CButton
                    mainClass="mt-10"
                    addClass="gradient p-5I"
                    active={true}
                    type="submit"
                  >
                    <Save className="btn-icon" />
                    {selBrand?.id ? "Update" : "Save"} Brand Kit
                  </CButton>
                </div>
              </div>
            </form>
          </div>)}
      </div>
      {/* {showBrandModal && (
        <BrandKitModal
          show={showBrandModal}
          brand={selBrand}
          onClose={onCloseBrandModal}
          onHandleUpdate={(data) => setBrands(preworkBrand(data))}
        />
      )} */}
    </DashboardLayout>
  );
}