import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function CImage({
  src,
  alt = "",
  addClass = "",
  width,
  height,
  onClick = () => {},
  placeholder = "",
  style = {},
}) {
  return (
    <LazyLoadImage
      className={addClass}
      alt={alt}
      effect="blur"
      src={src}
      width={width}
      height={height}
      onClick={onClick}
      useIntersectionObserver
      placeholderSrc={placeholder || ""}
      style={style}
    />
  );
}
