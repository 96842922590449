import { AdminLayout } from "components/layouts/AdminLayout";
import { CButton } from "components/atoms/CButton";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useState, useContext, useRef, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import { Slide } from "react-awesome-reveal";
import { getApi } from "utils/customNetwork";
import { ReactComponent as Upload } from "assets/imgs/upload.svg";
import { CTextarea } from "components/atoms/CTextarea";
import LogoCard from "components/atoms/LogoCard";
import "./styles.scss";
export function AdminHomePage() {
    const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
    const imageInputRef = useRef(null);
    const logoInputRef = useRef(null);
    const [formData, setFormData] = useState({
        id: "",
        bannerSection: {
            bannerMainHeading: "",
            bannerSubHeading: "",
            bannerPeopleHeading: "",
        },
        informationSection: [
            { id: 1, value: "" },
            { id: 2, value: "" },
        ],
        imageSection: {
            urls: [],
            tempUrls: [],
            uploadedFiles: [],
            deletedUrls: [],
        },
        logoSection: {
            urls: [],
            tempUrls: [],
            uploadedFiles: [],
            deletedUrls: [],
        },
        traditionalPhotographySection: [
            { id: 1, value: "" },
            { id: 2, value: "" },
        ],
        creativioShotsSection: [
            { id: 1, value: "" },
            { id: 2, value: "" },
        ],
    });

    const fetchHomeData = async () => {
        try {
            setLoading(true);
            checkToken();
            const response = await getApi("/getHomeData");
            if (response?.data) {
                const { banner_section, information_section, traditional_photography, creativio_shots, image_section, logo_section, id } = response.data;

                setFormData((prev) => ({
                    ...prev,
                    id: id || "",
                    bannerSection: banner_section || { bannerMainHeading: "", bannerSubHeading: "", bannerPeopleHeading: ""},
                    informationSection: information_section ? Object.values(information_section).map((info, index) => ({
                        id: index + 1,
                        value: info,
                    })) : [{ id: 1, value: "" }, { id: 2, value: "" }],
                    traditionalPhotographySection: traditional_photography ? Object.values(traditional_photography).map((info, index) => ({
                        id: index + 1,
                        value: info,
                    })) : [{ id: 1, value: "" }, { id: 2, value: "" }],
                    creativioShotsSection: creativio_shots ? Object.values(creativio_shots).map((info, index) => ({
                        id: index + 1,
                        value: info,
                    })) : [{ id: 1, value: "" }, { id: 2, value: "" }],
                    imageSection: image_section || { urls: [], tempUrls: [], uploadedFiles: [], deletedUrls: [] },
                    logoSection: logo_section || { urls: [], tempUrls: [], uploadedFiles: [], deletedUrls: [] },
                }));
            }
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHomeData();
    }, []);

    const handleChange = (e) => {
        const {name, value } = e.target;
        const [section, field] = name.split(".");

        setFormData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.bannerSection.bannerMainHeading.trim() === "" || formData.bannerSection.bannerSubHeading.trim() === "" || formData.bannerSection.bannerPeopleHeading.trim() === "" || formData.informationSection.some((field) => field.value.trim() === "") || formData.traditionalPhotographySection.some((field) => field.value.trim() === "") || formData.creativioShotsSection.some((field) => field.value.trim() === "")) {
            showNoti("error", "Please fill all the required fields.");
            return;
        }

        if(formData.imageSection.urls.length + formData.imageSection.uploadedFiles.length < 2) {
            showNoti("error", "At least 2 images are required.");
            return;
        }

        if(formData.logoSection.urls.length + formData.logoSection.uploadedFiles.length < 2) {
            showNoti("error", "At least 2 logos are required.");
            return;
        }

        const formDataToSubmit = new FormData();

        formDataToSubmit.append("banner_section[bannerMainHeading]", formData.bannerSection.bannerMainHeading);
        formDataToSubmit.append("banner_section[bannerSubHeading]", formData.bannerSection.bannerSubHeading);
        formDataToSubmit.append("banner_section[bannerPeopleHeading]", formData.bannerSection.bannerPeopleHeading);

        formData.informationSection.forEach((field, index) => {
            formDataToSubmit.append(`information_section[${index + 1}]`, field.value);
        });

        formData.traditionalPhotographySection.forEach((field, index) => {
            formDataToSubmit.append(`traditional_photography[${index + 1}]`, field.value);
        });

        formData.creativioShotsSection.forEach((field, index) => {
            formDataToSubmit.append(`creativio_shots[${index + 1}]`, field.value);
        });

        // Append urls array
        formData.imageSection.urls.forEach((url, index) => {
            formDataToSubmit.append(`image_section[urls][${index}]`, url);
        });
        // Append uploadedFiles array
        formData.imageSection.uploadedFiles.forEach((file, index) => {
            formDataToSubmit.append(`image_section[uploadedFiles][${index}]`, file);
        });
        // Append deletedUrls array
        formData.imageSection.deletedUrls.forEach((url, index) => {
            formDataToSubmit.append(`image_section[deletedUrls][${index}]`, url);
        });

        // Append urls array
        formData.logoSection.urls.forEach((url, index) => {
            formDataToSubmit.append(`logo_section[urls][${index}]`, url);
        });
        // Append uploadedFiles array
        formData.logoSection.uploadedFiles.forEach((file, index) => {
            formDataToSubmit.append(`logo_section[uploadedFiles][${index}]`, file);
        });
        // Append deletedUrls array
        formData.logoSection.deletedUrls.forEach((url, index) => {
            formDataToSubmit.append(`logo_section[deletedUrls][${index}]`, url);
        });

        let apiUrl = "/admin/saveHomeData";

        try {
            setLoading(true);
            checkToken();
            const response = await getApi(apiUrl, "POST", formDataToSubmit);
            if (formData.id) {
                showNoti("success", "Home Page Updated Successfully!");
            } else {
                showNoti("success", "Home Page Added Successfully!");
            }
            fetchHomeData();
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };

    const handleInformationFieldChange = (e, index) => {
        const { value } = e.target;
        setFormData((prev) => {
            const updatedInformationSection = prev.informationSection.map((field, idx) =>
                idx === index ? { ...field, value } : field
            );
            return { ...prev, informationSection: updatedInformationSection };
        });
    };

    const handleTraditionalFieldChange = (e, index) => {
        const { value } = e.target;
        setFormData((prev) => {
            const updatedTraditionalSection = prev.traditionalPhotographySection.map((field, idx) =>
                idx === index ? { ...field, value } : field
            );
            return { ...prev, traditionalPhotographySection: updatedTraditionalSection };
        });
    };

    const handleCreativioFieldChange = (e, index) => {
        const { value } = e.target;
        setFormData((prev) => {
            const updatedCreativioSection = prev.creativioShotsSection.map((field, idx) =>
                idx === index ? { ...field, value } : field
            );
            return { ...prev, creativioShotsSection: updatedCreativioSection };
        });
    };

    const onImageUpload = () => {
        if(formData.imageSection.urls.length + formData.imageSection.uploadedFiles.length < 10) {
            imageInputRef.current.click();
        } else {
            showNoti("warning", "Maximum of 10 images are allowed.");
        }
    };

    const onLogoUpload = () => {
        if (formData.logoSection.urls.length + formData.logoSection.uploadedFiles.length < 10) {
            logoInputRef.current.click();
        } else {
            showNoti("warning", "Maximum of 10 logos are allowed.");
        }
    };
    
    const handleImageUpload = (event, section) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newImageUrl = reader.result;
                setFormData((prevState) => ({
                    ...prevState,
                    [section]: {
                        ...prevState[section],
                        tempUrls: [...prevState[section].tempUrls, newImageUrl],
                        uploadedFiles: [...prevState[section].uploadedFiles, file],
                    },
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const onDeleteImage = (index, isFile, section) => {
        if (formData[section].urls.length + formData[section].uploadedFiles.length <= 2) {
            showNoti("warning", "Minimum of 2 images are required.");
        } else {
            setFormData((prevData) => {
                const updatedSection = { ...prevData[section] };
    
                if (isFile) {
                    updatedSection.tempUrls.splice(index, 1);
                    updatedSection.uploadedFiles.splice(index, 1);
                } else {
                    const deletedImage = updatedSection.urls.splice(index, 1)[0];
                    updatedSection.deletedUrls.push(deletedImage);
                }
                
                return {
                    ...prevData,
                    [section]: updatedSection,
                };
            });
        }
    };

    const handleAddField = (section) => {
        const newId = formData[section].length + 1;
        if (newId <= 10) {
            setFormData((prev) => ({
                ...prev,
                [section]: [...prev[section], { id: newId, value: "" }],
            }));
        } else {
            showNoti("warning", "Maximum of 10 fields are allowed.");
        }
    };

    const handleRemoveField = (index, section) => {
        setFormData((prev) => {
            if (prev[section].length > 2) {
                const updatedSection = prev[section].filter((_, idx) => idx !== index);
                const reIndexedSection = updatedSection.map((field, idx) => ({ ...field, id: idx + 1 }));
                return { ...prev, [section]: reIndexedSection };
            } else {
                showNoti("warning", "At least 2 fields are required.");
                return prev;
            }
        });
    };

    return (
        <AdminLayout
            page="dashboard"
            caption="Dashboard"
            message="Welcome back admin"
        >
            <div className="admin-home-page body-container">
                <div className="head">
                    <div className="header-testimonial">
                        <Slide className="flex1">
                            <h1>
                                <span>Home Page</span>
                            </h1>
                        </Slide>
                    </div>

                    <div className="mail-settings settings">
                        <form onSubmit={handleSubmit}>
                            <div className="section-container">
                                <h3 className="section-title">Banner Section</h3>
                                <div className="main-body-container">
                                    <div className="form-group mt-20">
                                        <label htmlFor="banner_main_heading">Banner Main Heading:</label>
                                        <CTextarea
                                            id="banner_main_heading"
                                            name="bannerSection.bannerMainHeading"
                                            addClass="banner_main_heading"
                                            placeholder="Banner Main Heading"
                                            value={formData.bannerSection.bannerMainHeading}
                                            onChange={handleChange}
                                            rows="2"
                                            required
                                        ></CTextarea>
                                    </div>

                                    <div className="form-group mt-20">
                                        <label htmlFor="banner_sub_heading">Banner Sub Heading:</label>
                                        <CTextarea
                                            id="banner_sub_heading"
                                            name="bannerSection.bannerSubHeading"
                                            addClass="banner_sub_heading"
                                            placeholder="Banner Sub Heading:"
                                            value={formData.bannerSection.bannerSubHeading}
                                            onChange={handleChange}
                                            rows="2"
                                            required
                                        ></CTextarea>
                                    </div>

                                    <div className="form-group mt-20">
                                        <label htmlFor="banner_people_heading">Banner People Heading:</label>
                                        <CTextarea
                                            id="banner_people_heading"
                                            name="bannerSection.bannerPeopleHeading"
                                            addClass="banner_people_heading"
                                            placeholder="Banner People Heading"
                                            value={formData.bannerSection.bannerPeopleHeading}
                                            onChange={handleChange}
                                            rows="2"
                                            required
                                        ></CTextarea>
                                    </div>
                                </div>
                            </div>

                            {/* Information Section */}
                            <div className="section-container">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h3 className="section-title">Information Section</h3>
                                    <CButton onClick={() => handleAddField("informationSection")} className="add-field-button">
                                        Add Information Field
                                    </CButton>
                                </div>
                                <div className="main-body-container">
                                    {formData.informationSection.map((field, index) => (
                                        <div key={field.id} className="mt-20">
                                            <label htmlFor={`information_${field.id}`}>Information {field.id} Details:</label>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <input
                                                    type="text"
                                                    id={`information_${field.id}`}
                                                    name={`informationSection[${field.id}]`}
                                                    placeholder={`Information ${field.id}`}
                                                    value={field.value}
                                                    onChange={(e) => handleInformationFieldChange(e, index)}
                                                    required
                                                />
                                                <CButton onClick={() => handleRemoveField(index, "informationSection")} disabled={formData.informationSection.length <= 2}>
                                                    Remove
                                                </CButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Image Section */}
                            <div className="section-container">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h3 className="section-title">Image Section</h3>
                                </div>
                                <div className="image-section">
                                    <div className="image-upload-area">
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            onChange={(e) => handleImageUpload(e, "imageSection")}
                                            style={{ display: "none" }}
                                            ref={imageInputRef}
                                        />
                                        <div className="custom-image-upload border-gradient" onClick={onImageUpload}>
                                            <Upload />
                                            <p>Add a Image or just drag and drop one here</p>
                                        </div>
                                    </div>
                                </div>
                                {(formData.imageSection?.urls?.length > 0 || formData.imageSection?.tempUrls?.length > 0) && (
                                    <div className="uploaded-images mt-10">
                                        {formData.imageSection?.urls?.map((imgUrl, index) => (
                                            <LogoCard
                                                key={index}
                                                alt={`Image ${index + 1}`}
                                                imgUrl={imgUrl}
                                                onDelete={(isFile) => onDeleteImage(index, isFile, "imageSection")}
                                                isFile={false}
                                                width={150}
                                                height={150}
                                            />
                                        ))}
                                        {formData.imageSection?.tempUrls?.map((imgUrl, index) => (
                                            <LogoCard
                                                key={index}
                                                alt={`Image ${formData.imageSection?.urls?.length + index + 1}`}
                                                imgUrl={imgUrl}
                                                onDelete={(isFile) => onDeleteImage(index, isFile, "imageSection")}
                                                isFile={true}
                                                width={150}
                                                height={150}
                                            />
                                        ))}
                                        <Tooltip id="tip-logo-delete" />
                                    </div>
                                )}
                            </div>

                            {/* Logo Section */}
                            <div className="section-container">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h3 className="section-title">Social Media Logo Section</h3>
                                </div>
                                <div className="image-section">
                                    <div className="image-upload-area">
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            onChange={(e) => handleImageUpload(e, "logoSection")}
                                            style={{ display: "none" }}
                                            ref={logoInputRef}
                                        />
                                        <div className="custom-image-upload border-gradient" onClick={onLogoUpload}>
                                            <Upload />
                                            <p>Add a Logo or just drag and drop one here</p>
                                        </div>
                                    </div>
                                </div>
                                {(formData.logoSection?.urls?.length > 0 || formData.logoSection?.tempUrls?.length > 0) && (
                                    <div className="uploaded-images mt-10">
                                        {formData.logoSection?.urls?.map((imgUrl, index) => (
                                            <LogoCard
                                                key={index}
                                                alt={`Image ${index + 1}`}
                                                imgUrl={imgUrl}
                                                onDelete={(isFile) => onDeleteImage(index, isFile, "logoSection")}
                                                isFile={false}
                                                width={150}
                                                height={150}
                                            />
                                        ))}
                                        {formData.logoSection?.tempUrls?.map((imgUrl, index) => (
                                            <LogoCard
                                                key={index}
                                                alt={`Image ${formData.logoSection?.urls?.length + index + 1}`}
                                                imgUrl={imgUrl}
                                                onDelete={(isFile) => onDeleteImage(index, isFile, "logoSection")}
                                                isFile={true}
                                                width={150}
                                                height={150}
                                            />
                                        ))}
                                        <Tooltip id="tip-logo-delete" />
                                    </div>
                                )}
                            </div>

                            {/* Traditional Photography Section */}
                            <div className="section-container">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h3 className="section-title">Traditional Photography Section</h3>
                                    <CButton onClick={() => handleAddField("traditionalPhotographySection")} className="add-field-button">
                                        Add Traditional Field
                                    </CButton>
                                </div>
                                <div className="main-body-container">
                                    {formData.traditionalPhotographySection.map((field, index) => (
                                        <div key={field.id} className="mt-20">
                                            <label htmlFor={`traditional_${field.id}`}>Traditional {field.id} Details:</label>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <input
                                                    type="text"
                                                    id={`traditional_${field.id}`}
                                                    name={`traditionalPhotographySection[${field.id}]`}
                                                    placeholder={`Traditional Details ${field.id}`}
                                                    value={field.value}
                                                    onChange={(e) => handleTraditionalFieldChange(e, index)}
                                                    required
                                                />
                                                <CButton onClick={() => handleRemoveField(index, "traditionalPhotographySection")} disabled={formData.traditionalPhotographySection.length <= 2}>
                                                    Remove
                                                </CButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Creativio Shots Section */}
                            <div className="section-container">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h3 className="section-title">Creativio Shots Section</h3>
                                    <CButton onClick={() => handleAddField("creativioShotsSection")} className="add-field-button">
                                        Add Creativio Field
                                    </CButton>
                                </div>
                                <div className="main-body-container">
                                    {formData.creativioShotsSection.map((field, index) => (
                                        <div key={field.id} className="mt-20">
                                            <label htmlFor={`creativio_${field.id}`}>Creativio {field.id} Details:</label>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <input
                                                    type="text"
                                                    id={`creativio_${field.id}`}
                                                    name={`creativioShotsSection[${field.id}]`}
                                                    placeholder={`Creativio Details ${field.id}`}
                                                    value={field.value}
                                                    onChange={(e) => handleCreativioFieldChange(e, index)}
                                                    required
                                                />
                                                <CButton onClick={() => handleRemoveField(index, "creativioShotsSection")} disabled={formData.creativioShotsSection.length <= 2}>
                                                    Remove
                                                </CButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <button className="custom-button primary active mt-25" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}
